<template>
  <div>
    <div style="margin-top: 10px; padding:5px 10px">
      <a-form-model layout="inline" :model="formInline">
        <a-form-model-item label="">
          <a-select v-model="formInline.result" style="width: 120px" @change="getList(1)">
            <a-select-option v-for="(item,index) in resultArr" :key="index" :value="index">
              {{ item }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="">
          <a-input v-model="formInline.keywords" class="w300" placeholder="输入姓名、电话、身份证号码搜索"
                   @keyup="getList(1)"></a-input>
        </a-form-model-item>
        <template v-if="this.list_status != 4">
          <template v-if="this.list_status != 3">
            <a-form-model-item label="">
              <a-popconfirm title="是否要把检测结果设为全部阴性？" @confirm="changeResult(1)">
                <a-icon slot="icon" type="question-circle-o" style="color: red"/>
                <a-button type="Default">
                  全部阴性
                </a-button>
              </a-popconfirm>
            </a-form-model-item>
            <a-form-model-item label="">
              <a-popconfirm title="是否要把检测结果设为全部阳性？" @confirm="changeResult(2)">
                <a-icon slot="icon" type="question-circle-o" style="color: red"/>
                <a-button type="Default">
                  全部阳性
                </a-button>
              </a-popconfirm>
            </a-form-model-item>
          </template>
          <a-form-model-item label="">
            <a-button type="Default" @click="exportIt('a')">
              导出上传时结果
            </a-button>
          </a-form-model-item>
          <a-form-model-item label="">
            <a-button type="Default" @click="exportIt('b')">
              导出大疫情网
            </a-button>
          </a-form-model-item>
          <a-form-model-item label="">
            <a-button type="Default" @click="exportIt('c')">
              导出大数据
            </a-button>
          </a-form-model-item>
          <a-form-model-item label="">
            <a-button type="Default" @click="exportEnvironment()">
              导出物品检测
            </a-button>
          </a-form-model-item>
          <a-form-model-item label="">
            <a-button type="primary" @click="printChecked">
              打印选中报告单
            </a-button>
          </a-form-model-item>
          <a-form-model-item label="">
            <a-button type="primary" @click="printAll">
              打印全部报告单
            </a-button>
          </a-form-model-item>
          <template v-if="this.list_status != 3">
            <a-form-model-item label="">
              <a-button type="primary" @click="chooseAuditor('audit')">
                审核
              </a-button>
            </a-form-model-item>
            <a-form-model-item label="">
              <a-button type="danger" @click="chooseAuditor('cancel')">
                取消审核
              </a-button>
            </a-form-model-item>

            <a-form-model-item label="">
              <a-button type="primary" @click="enterResult()">
                录入检测结果
              </a-button>
            </a-form-model-item>
            <a-form-model-item label="">
              <a-button type="primary" @click="changeAddResultTime()">
                修改送检时间/结果时间
              </a-button>
            </a-form-model-item>
          </template>

        </template>

      </a-form-model>
    </div>
    <table class="layui-table">
      <thead>
      <tr>
        <th>
          <a-checkbox @click="checkAll"></a-checkbox>
        </th>
        <th>标本号</th>
        <th>姓名</th>
        <th>性别</th>
        <th>年龄</th>
        <th>身份证号</th>
        <th>手机号</th>
        <th>送(采)样时间</th>
        <th>送检(采样)人</th>
        <th>人员类别</th>
        <th>检验信息</th>
        <th>审核信息</th>
        <th>结果</th>
        <!--        <th>打印</th>-->
      </tr>
      </thead>
      <tbody>
      <template v-for="list_item in list">
        <tr v-for="(item,index) in list_item.list">
          <td>
            <a-checkbox v-model="item.checked"></a-checkbox>
          </td>
          <td v-if="index===0" :rowspan="list_item.list.length">标本{{ list_item.group_id }}号</td>
          <td>{{ item.username }}</td>
          <td>{{ item.sex }}</td>
          <td>{{ item.age }}</td>
          <td>{{ item.id_card }}</td>
          <td>{{ item.phone }}</td>
          <td>{{ item.add_date }}</td>
          <td>{{ item.unit }}</td>
          <td>{{ item.category }}
            <br>
            <span v-if="item.type===1">【人员】</span>
            <span v-else>【物品】</span>
            {{ item.type_son_msg }}
          </td>
          <td v-if="index===0" :rowspan="list_item.list.length">{{ item.checker_name }}</br>{{ item.check_time }}</td>
          <td v-if="index===0" :rowspan="list_item.list.length">{{ item.auditor_name }}</br>{{ item.audit_time }}</td>
          <td v-if="index===0" :rowspan="list_item.list.length">
            <template v-if="item.auditor===0">
              <a-select v-model="list_item.result" style="width: 120px"
                        @change="(value,option)=>handleChange(value,option,list_item.group_id)">
                <a-select-option :value="0">
                  未检测
                </a-select-option>
                <a-select-option :value="1">
                  阴性
                </a-select-option>
                <a-select-option :value="2">
                  阳性
                </a-select-option>
              </a-select>
            </template>
            <template v-else>
              <span v-if="item.result===0">未检测</span>
              <span style="color:blue;" v-if="item.result===1">阴性</span>
              <span style="color:red;" v-if="item.result===2">阳性</span>
            </template>
          </td>
          <!--          <td>-->
          <!--            <a-button v-if="item.auditor>0" type="primary" @click="printIt(item.id)">打印</a-button>-->
          <!--          </td>-->
        </tr>
      </template>
      </tbody>
    </table>
    <div>
      <a-pagination v-if="list.length>0" v-model="pagination.page" :total="pagination.total"
                    :pageSize="pagination.pageSize"
                    show-less-items @change="onChange"/>
    </div>
    <EnterResult :visible="enterResultVisible" :equipmentList="equipmentList" :enterResultInfo="enterResultInfo"
                 @close="enterResultHidden"></EnterResult>
    <shenheren :visible="shenherenVisible" :title="sheherenTitle" @doAuditCancel="doAuditCancel" @doAudit="doAudit"
               :auditInfo="auditInfo" :auditors="auditors" :shenherenaction="shenherenaction"
               :close="shenherenHidden"></shenheren>
    <change-time :visible="change_time_visible" title="修改时间" :info="info" @close="changetimeHidden"
                 @saveIt="saveAddResultTime"></change-time>

  </div>
</template>
<script>
import {hs_print} from "../../../utils/hs_print";
import EnterResult from "../hesuanjeiguo/Enter-result/Enter-result";
import store from "../../../store/store";
import Shenheren from "../hesuanjeiguo/shenheren/shenheren";
import ChangeTime from "./changeTime";

export default {
  name: "hesuanjiance",
  components: {ChangeTime, Shenheren, EnterResult},
  data() {
    return {
      shenherenaction: "",//审核操作
      auditors: [],//已审核人列表
      equipmentList: [],
      enterResultInfo: {
        acid: 0,
        equipment: 0,
        Specimen_number: '',
        result: 1,
      },
      sheherenTitle: '',
      auditInfo: {},//审核信息
      shenherenVisible: false,
      enterResultVisible: false,
      resultArr: ['全部状态', '阴性', '阳性'],
      acid: 0,
      formInline: {
        result: 0,
        keywords: '',
      },
      list: [],//检测人员列表
      list_status: [],
      pagination: {
        'current': 1,
        'total': 1,
        'pageSize': 1
      },
      info: {
        revisable: 0,//是否可修改
        complte: 0,//审核权限
      },//检测详情
      change_time_visible: false
    };
  },
  created() {
    this.acid = this.$route.params.id
    this.enterResultInfo.acid = this.$route.params.id
    this.getAcidInfo()
  },
  mounted() {
    this.getList(1)
    this.getEquipmentList()
  },
  methods: {
    getAuditorList() {
      this.$sa0.file({
        url: this.$api('Auditor_list'),
        data: {
          id: this.acid,
        },
      }).then((response) => {
        this.$sa0.response({
          response: response.data,
          then: (response) => {
            if (response.code === 0) {
              this.auditors = response.data.nucleic_acid_group.map(item => {
                item.password = ''
                return item
              })

            }
          }
        })
      })
    },
    enterResultHidden() {
      this.enterResultVisible = false
    },
    shenherenHidden() {
      this.shenherenVisible = false
    },
    chooseAuditor(action) {
      if (action === 'cancel') {
        this.getAuditorList()
        this.sheherenTitle = '审核人列表-取消审核'
      } else {
        this.sheherenTitle = '审核人列表-审核'
        if (this.info.complete === 1) {
          layer.msg('已审核通过不能在审核')
          return
        }
      }
      this.shenherenaction = action
      this.shenherenVisible = true
      this.auditInfo = {
        id: this.acid,
        action: action,
        auditor: 0,//审核人ID
        password: ''//审核人密码
      }
    },
    enterResult() {
      if (this.info.complete === 1) {
        layer.msg('此核酸检测已完成审核')
        return
      }
      this.enterResultVisible = true
      this.enterResultInfo.Specimen_number = ''
      this.enterResultInfo.result = 1
      console.log(this.enterResultInfo)
    },
    exportIt(type) {
      if (this.info.complete === 0) {
        layer.msg('此核酸检测未完成审核')
        return
      }
      location.href = this.$api('核酸检测导出') + '?id=' + this.acid + '&type=' + type
      return
    },
    exportEnvironment() {
      if (this.info.type_2_count === 0) {
        layer.msg('此核酸检测没有物品')
        return
      }

      location.href = this.$api('核酸检测环境标本导出') + '?id=' + this.acid
    },
    onChange(p) {
      this.getList(p)
    },
    printIt(id) {
      hs_print([id])
    },
    printChecked() {
      if (this.info.complete === 0) {
        layer.msg('此核酸检测未完成审核')
        return
      }
      let checked = this.list.map(item => {
        return item.list.filter(i => {
          return i.checked
        }).map(ii => ii.id)

      })
      let ids = checked.reduce(function (a, b) {
        return a.concat(b)
      })
      hs_print(ids)
    },
    printAll() {
      if (this.info.complete === 0) {
        layer.msg('此核酸检测未完成审核')
        return
      }
      this.$sa0.file({
        url: this.$api('核酸检测打印所有报告'),
        data: {
          id: this.acid,
        },
      }).then((response) => {
        this.$sa0.response({
          response: response.data,
          then: (response) => {
            if (response.code === 0) {
              let ids = response.data
              if (ids.length > 0) {
                hs_print(ids)
              } else {
                layer.msg('没有要打印的文件')
              }
            }
          }
        })
      })
    },
    //审核取消审核
    doAudit(auditInfo) {
      let param = auditInfo
      this.$sa0.file({
        url: this.$api('核酸检测结果审核'),
        data: param,
      }).then((response) => {
        this.$sa0.response({
          response: response.data,
          then: (response) => {
            this.shenherenVisible = false
            layer.msg(response.message)
            location.reload()
          },
          error: (response) => {
            layer.msg(response.message)
          },
        })
      })
    },
    doAuditCancel(param) {
      let data = {
        content: param.map(item => {
          let r = {
            'id': item.auditor,
            'password': item.password,
          }
          return r
        }),
        id: this.acid
      }
      this.$sa0.file({
        url: this.$api('Cancle_nucleic'),
        data: data,
      }).then((response) => {
        this.$sa0.response({
          response: response.data,
          then: (response) => {
            this.shenherenVisible = false
            layer.msg(response.message)
            location.reload()
          },
          error: (response) => {
            layer.msg(response.message)
          },
        })
      })
    },
    handleChange(value, option, group_id) {
      const self = this;
      this.$confirm({
        content: '确认修改检测结果？',
        onOk() {
          self.$sa0.file({
            url: self.$api('核酸检测结果修改'),
            data: {
              id: self.acid,
              action: 'different',
              group_id: group_id,
              result: value
            },
          }).then((response) => {
            self.$sa0.response({
              response: response.data,
              then: (response) => {
                layer.msg(response.message)
                location.reload()
              }
            })
          })
        },
        cancelText: '取消',
        onCancel() {
          self.$destroyAll();
        },
      });


    },
    changeResult(result) {
      if (this.info.revisable === 0) {
        layer.msg('已审核通过不许可修改')
        return
      }
      this.$sa0.file({
        url: this.$api('核酸检测结果修改'),
        data: {
          id: this.acid,
          action: 'all',
          result: result
        },
      }).then((response) => {
        this.$sa0.response({
          response: response.data,
          then: (response) => {
            layer.msg(response.message)
            location.reload()
          }
        })
      })
    },
    getAcidInfo() {
      this.$sa0.file({
        url: this.$api('核酸检测详情'),
        data: {id: this.acid},
      }).then((response) => {
        this.$sa0.response({
          response: response.data,
          then: (response) => {
            this.info = response.data
            store.state.breadcrumb.push(
                {
                  title: this.info.identifier + '-' + this.info.batch + '-' + this.info.unit_name,
                  key: "bbbbbb",
                  path: ''
                }
            )
            console.log(store.state.breadcrumb)
          }
        })
      })
    },
    checkAll() {
      this.list = this.list.map(item => {
        item.list.map(i => {
              i.checked = !i.checked
              return i
            }
        )
        return item
      })
    },
    getList(page) {
      let param = this.formInline
      param.id = this.acid
      param.page = page
      this.$sa0.file({
        url: this.$api('核酸检测详细列表'),
        data: param,
      }).then((response) => {
        this.$sa0.response({
          response: response.data,
          then: (response) => {
            this.pagination.current = response.data.pagination.current
            this.pagination.total = response.data.pagination.total
            this.pagination.pageSize = response.data.pagination.pageSize
            this.list_status = response.data.status
            this.list = response.data.list.map(item => {
              console.log(this.list)
              item.list.map(i => {
                i.checked = false
                return i
              })
              return item
            })
          },

        })
      })
    },
    getEquipmentList() {//设备列表
      this.$sa0.file({
        url: this.$api('Apparatus_list'),
        data: {},
      }).then((response) => {
        this.$sa0.response({
          response: response.data,
          then: (response) => {
            if (response.code === 0) {
              this.equipmentList = response.data.list
              this.enterResultInfo.equipment = response.data.list[0].id
            }
          }
        })
      })
    },
    changeAddResultTime() {//手动修改送检/结果时间
      if (this.info.complete === 0) {
        layer.msg('此核酸检测未完成审核')
        return
      } else {
        this.change_time_visible = true
      }
    },
    saveAddResultTime(data) {//手动修改送检/结果时间 保存
      this.$sa0.file({
        url: this.$api('保存核酸检测增加时间和结果时间'),
        data: data,
      }).then((response) => {
        this.$sa0.response({
          response: response.data,
          then: (response) => {
            this.change_time_visible = false
            layer.msg(response.message)
          },
          error: (response) => {
            layer.msg(response.message)
          }
      })
      })
    },
    changetimeHidden(){
      this.change_time_visible=false
    }
  },
}
</script>

<style scoped>
.w300 {
  width: 300px
}


.bgc {
  width: 100%;
  background: #f9f9f9;
  padding: 10px
}

</style>
