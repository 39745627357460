<template>
  <div>
    <a-drawer :title="title" width="640" placement="right" :closable="true" :visible="visible" @close="onClose">
      <div>
        <a-row>
          <a-col :span="14">
            <a-form-model>
              <a-form-model-item label="送检时间">
                <a-date-picker v-model="info.handle_add_time" show-time format="YYYY-MM-DD HH:mm:ss" valueFormat="YYYY-MM-DD HH:mm:ss"
                               class="w200"/>
              </a-form-model-item>
              <a-form-model-item label="结果时间">
                <a-date-picker v-model="info.handle_result_time" show-time format="YYYY-MM-DD HH:mm:ss" valueFormat="YYYY-MM-DD HH:mm:ss"
                               class="w200"/>
              </a-form-model-item>
              <a-form-model-item>
                <a-button type="primary" @click="doSave">
                  保存
                </a-button>
              </a-form-model-item>
            </a-form-model>
          </a-col>
        </a-row>
      </div>
    </a-drawer>
  </div>
</template>

<script>
export default {
  name: "changeTime",
  props: ['visible', 'title', 'info'],
  mounted() {

  },
  data() {
    return {}
  },
  methods: {
    onClose() {
      this.$emit('close')
    },
    doSave(){
      let param={
        id:this.info.id,
        handle_add_time:this.info.handle_add_time,
        handle_result_time:this.info.handle_result_time
      }
      this.$emit('saveIt',param)
    }
  }
}
</script>

<style scoped>
.w200 {
  width: 200px;
}
</style>