<template>
  <div>
    <!--:visible="visible"      赋值 显示变量-->
    <!--@ok="handleOk"          赋值 确认函数-->
    <!--@cancel="handleCancel"  赋值 取消函数-->
    <a-modal title="录入检测结果" okText="确定" cancelText="取消" width="420px" :visible="visible" @ok="handleOk"
             @cancel="handleCancel" :zIndex="1000">
      <div>
        <a-form-model>
          <a-form-model-item label="检验设备" :label-col="{ span: 6, offset: 0 }" :wrapper-col="{ span:16, offset: 0}">
            <a-select v-model="enterResultInfo.equipment">
              <a-select-option v-for="item in equipmentList" :key="item.id" :value="item.id">{{ item.name }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="标本编号" :label-col="{ span: 6, offset: 0 }" :wrapper-col="{ span:16, offset: 0}">
            <a-input placeholder="请输入样本编号,如：3,4,5-7,9-10" v-model="enterResultInfo.Specimen_number"></a-input>
          </a-form-model-item>
          <a-form-model-item label="检测结果" :label-col="{ span: 6, offset: 0 }" :wrapper-col="{ span:16, offset: 0}">
            <a-select v-model="enterResultInfo.result">
              <a-select-option :value="1">阴性</a-select-option>
              <a-select-option :value="2">阳性</a-select-option>
            </a-select>
          </a-form-model-item>
        </a-form-model>
      </div>
    </a-modal>

  </div>
</template>
<script>

/***
 Name: example4
 Code: sa0ChunLuyu
 Time: 2021/10/16 10:19
 Remark: 编辑弹框示例
 */
export default {
  // 接收 显示变量 和 确定 取消 函数
  props: ['ok', 'cancel', 'visible', 'enterResultInfo', 'equipmentList'],
  data() {
    return {}
  },
  mounted() {
  },
  methods: {
    // 定义 组件 自身的确定函数
    handleOk() {
      //console.log(this.enterResultInfo)
      if (this.enterResultInfo.Specimen_number === '') return  layer.msg('请输入样本编号')
      if (this.enterResultInfo.Specimen_number.length > 0) {
        this.$sa0.file({
          url: this.$api('核酸检测结果修改'),
          data: {
            id: this.enterResultInfo.acid,
            action: 'groups',
            equipment: this.enterResultInfo.equipment,
            Specimen_number: this.enterResultInfo.Specimen_number,
            result: this.enterResultInfo.result
          },
        }).then((response) => {
          this.$sa0.response({
            response: response.data,
            then: (response) => {
              layer.msg(response.message)
              location.reload()
            },
            error: (response) => {
              layer.msg(response.message)
            }
          })
        })
      }
    },
    // 定义 组件 自身的取消函数
    handleCancel() {
      this.$emit('close')
    },
  }
}
</script>
<style scoped>

</style>
